import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import "rxjs/add/operator/map";
import { Http, Response, Headers, RequestOptions } from "@angular/http";

const url = environment.urlLink;

@Injectable()
export class UsersService {
  private headers: Headers;
  private options: RequestOptions;
  constructor(private http: Http) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.options = new RequestOptions({ headers: this.headers });
  }

  getUsers() {
    return this.http
      .get(url + "/users/gUsers")
      .map((response) => response.json());
  }

  fUser(data) {
    const body = data;
    return this.http
      .post(url + "/users/fUser", body, this.options)
      .map((res: Response) => res.json());
  }

  cUsers(data) {
    const body = data;
    return this.http
      .post(url + "/users/cUser", body, this.options)
      .map((res: Response) => res.json());
  }

  uUsers(data) {
    const body = data;
    return this.http
      .post(url + "/users/uUser", body, this.options)
      .map((res: Response) => res.json());
  }

  dUsers(data) {
    const body = data;
    return this.http
      .post(url + "/users/dUser", body, this.options)
      .map((res: Response) => res.json());
  }
}
